/* You can add global styles to this file, and also import other style files */
/* REDUCE FONT-SIZE GLOBALLY FOR SCALING DOWN PRIMGNG COMPONENTS */
html { font-size: 12px;}
/* D3-TOOLTIP */
.ui-tooltip{word-wrap: break-word;}
.d3-tip {padding: 6px;background: rgba(0, 0, 0, 0.74);color: #fff;border-radius: 5px;pointer-events: none;z-index: 12000!important;font-size: 10.5px;word-wrap: break-word;max-width: 301px;font-family: sans-serif;}
.d3-tip strong{color: #fff;}
/* Creates a small triangle extender for the tooltip */
.d3-tip:after {box-sizing: border-box;display: inline;font-size: 9px;width: 100%;line-height: 1;color: rgba(0, 0, 0, 0.8);position: absolute;pointer-events: none;}
/* Northward tooltips */
.d3-tip.n:after {content: "▼";margin: -1px 0 0 0;top: 100%;left: 0;text-align: center;}
/* Eastward tooltips */
.d3-tip.e:after {content: "◀";margin: -4px 0 0 0;top: 50%;left: -8px;}
/* Southward tooltips */
.d3-tip.s:after {content: "▲";margin: 0 0 1px 0;top: -8px;left: 0;text-align: center;}
/* Westward tooltips */
.d3-tip.w:after {content: "▶";margin: -4px 0 0 -1px;top: 50%;left: 100%;}
.d3-tip:empty {display: none;}

/* ARTICLE RELATED REPORT */
.relatedreportsdialog{font-size: 11px;width: 75vw;height: auto;max-height: 90vh;top: -10px;}
.relatedreportsdialog .p-dialog-content{width: 100%;height: auto;max-height: 79vh;min-height:75vh;}
.relatedreportsdialog p-table .link {cursor: pointer;color: #0000EE;text-decoration: underline;word-wrap: break-word;}
.relatedreportsdialog p-table .link:link{color: #0000EE;text-decoration: underline;}
.relatedreportsdialog p-table .link:visited{color: #551A8B;text-decoration: underline;}
.relatedreportsdialog .previewreport-wrapper .caption-wrapper{width: 100%;padding: 5px;background: #eaedf0;border: 1px solid #dbdbdb;}
.relatedreportsdialog .previewreport-wrapper .caption-left{display: inline-block;width: 70%;text-align: left;}
.relatedreportsdialog .previewreport-wrapper .caption-right{display: inline-block;width: 30%;text-align: right;}
.relatedreportsdialog .previewreport-wrapper .iframe-wrapper{border: 1px solid #dbdbdb;padding: 0px;}
.relatedreportsdialog .previewreport-wrapper .preview-iframe{width: 100%;display: block;}

/* SCONFIG ACTIVATION DIALOG */
.SConfigActivationDialog{font-size: 11px;width: 35vw;height: auto;max-height: 25vh;top: -12px;}
.SConfigActivationDialog .p-dialog-content{width: 100%;height: auto;max-height: 26vh;min-height:25vh;}

/* SCONFIG ACTIVATION DIALOG */
.ForwardNewsDialog{font-size: 11px;width: 35vw;height: auto;max-height: 55vh;top: -20px;}
.ForwardNewsDialog .p-dialog-content{width: 100%;height: auto;max-height: 56vh;min-height:55vh;}
.ForwardNewsDialog .p-dialog-content .fwarticle-label {color: black;font-weight: bold;}
.ForwardNewsDialog .p-dialog-content .fwarticle-label.margin-custom {margin-bottom: 0.1rem;}
.ForwardNewsDialog .p-dialog-content .display-block{display: block;}
.ForwardNewsDialog .p-dialog-content .pad-zero{padding: 0px;}
.ForwardNewsDialog .p-dialog-content .small-info{font-size: 80%;display: block;}
.ForwardNewsDialog .p-dialog-content p-autocomplete .fwarticle-autocomplete{width: 100%;}
.ForwardNewsDialog .p-dialog-content p-autocomplete .fwarticle-autocomplete ul.p-autocomplete-multiple-container{width: 100%;}
.ForwardNewsDialog .p-dialog-content p-autocomplete .fwarticle-autocomplete input.fwarticle-autocomplete-input{width: 100%;}
.ForwardNewsDialog .p-dialog-content p-checkbox .fwarticle-checkbox .p-checkbox-box{width: 17.5px;height: 17.5px;}

/* ARTICLE PAGE - POST TO CRM DIALOG */
.postcrmdialog{font-size: 11px;width: 35vw;height: auto;max-height: 45vh;}
.postcrmdialog .p-dialog-content{width: 100%;height: auto;max-height: 46vh;min-height:45vh;}
.postcrmdialog .p-dialog-content textarea.crm-textarea{width: 100%;}

/* ARTICLE PAGE - DISCLAIMER OVERLAYPANEL */
.disclaimer-overlay {width: 25vw;max-width: 26vw;}
.disclaimer-overlay .disclaimer-text{ font-style: italic;font-size: 12px;}
